@import 'ag-grid-enterprise/styles/ag-grid.css';
@import 'ag-grid-enterprise/styles/ag-theme-quartz.css';

:root {
  --app-background: #f1f1f1;
}

html, body {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--app-background);
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.ag-table {
  height: 100%;
  padding: 8px;
}
